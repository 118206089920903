import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  Grid  
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import './css/styles.css';
// Customizable Area End

import Contractmanagement2Controller, {
  Props,
  configJSON,
} from "./Contractmanagement2Controller";
import SideNav from "../../../components/src/SideNav";
import TopNavBar from "../../../components/src/TopNavBar";
import MyTeams from "./MyTeams.web";

export default class Contractmanagement2 extends Contractmanagement2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={webStyle.container}>
        <Grid container>
          <Grid item xs={3} md={2} className="sidebar-wrapper">
            <SideNav {...this.props.navigation} />
          </Grid>
          <Grid item xs={9} md={10} className="main-content-wrapper">
            <Grid>
              <TopNavBar currentPage={{ name: 'Teams', path: 'teams' }} />
              <Box style={webStyle.mainContainer}>
                <MyTeams {...this.props} />
              </Box >
            </Grid >
          </Grid >
        </Grid >     
      </Box >      
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  container: {
    background: '#F8F9FA',
    height: '100vh',
    overflow: 'auto',
  },
  mainContainer: {
    padding: '16px 0'
  },
};
// Customizable Area End
