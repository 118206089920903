import React from "react";
// Customizable Area Start
import { Avatar, Box, Button, Grid, IconButton, InputAdornment, Modal, TextField, Typography } from "@material-ui/core";
import SideNav from "../../../components/src/SideNav";
import TopNavBar from "../../../components/src/TopNavBar";
import './css/styles.css'
import { ErrorMessage, Field, Formik, FormikProps } from "formik";
import { closeIcon } from "../../leadmanagement/src/assets";
import { changePasswordSchema, personalAdminEditSchema, personalMemberEditSchema } from "../../../components/src/validations";
import Select from 'react-select';
import { lockIcon, uploadIcon, closeGrayIcon, user_active, searchIcon, dropDownIcon } from "./assets";
import ImageCropper from "../../../components/src/Cropper";
import { getHideUnHidePassword, getMonthDateFormat, getSanitizedValues, getSelectLabel, getToken, getIntitals, getTrueValidataions, getProfileName, getNullValues, capitalizeFirstLetter, getWrapperClassName, isApplicationNotReloaded } from "../../../components/src/commonUsage";
import { openEye, visiblityOff } from "../../email-account-login/src/assets";
//@ts-ignore
import ProfilePageSkeleton from "./ProfilePageSkeleton";
import TopNavSkeleton from "../../../components/src/TopNavSkeleton";
import Loader from "../../../components/src/Loader.web";
import {HelpAndSupport} from '../../../components/src/HelpAndSupport'
import { trackEvent } from "../../../components/src/analytics";
import { setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

import UserProfileBasicController, {
  Props
} from "./UserProfileBasicController";

export default class UserProfileBasicBlockWeb extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.fileInputRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  openFileInput = () => {
    // Trigger the file input element by clicking it programmatically
    this.fileInputRef.current.click();
  };

  DropDown=(formik:FormikProps<any>)=>{
    return(this.state.countryDropDown&&
    <Box
      data-test-id="dropDown"
      style={{
        width: "300px",
        background: "#FFF",
        zIndex: 999,
        boxShadow: "0px 4px 16px 0px #00000026",
        borderRadius: "8px",
        top:"220px",
        position:"fixed"
      }}
      onBlur={()=>{
        setTimeout(()=>{
          this.setState({countryDropDown:false,search:""})
        },500)
      }}
      >
      <Box>
          <Box display={"flex"} flexDirection={"row"} sx={{ justifyContent: "space-between", alignItems: "center" }}>

            <input
              className="pic-search-input"
              type="text"
              placeholder="Search"
              value={this.state.search}
              onChange={event => {
                this.setState({ search: event.target.value })
              }}
              autoFocus
              style={{ padding: "14px", border: "none", flex: "0.9", outline: "none", width: "100%", fontSize: "1rem" }}
            />
            <IconButton
              aria-label="email"
              edge="end"
              style={{ flex: "0.1" }}
            >
              <img src={searchIcon} />
            </IconButton>
          </Box>
          <Box style={{overflowY:"scroll",height: "200px"}}>
        {
          this.filterCountryData().map((country: any) => (
            <Box
              display={"flex"}
              alignItems={"center"}
              className="country-code"
              onClick={() => { 
                formik.setFieldValue('countryCode',`+${country.value}`)
                formik.setFieldValue('countryFlag', country.code)
                this.setState({countryDropDown:false,search:""})
                // this.setCountyCode(`+${country.value}`, country.code) 
              }}
            >
              <span className={`fi fi-${country.code}`}></span>
              <Typography
                style={{ fontSize: "16px", color: "#3C3E49", lineHeight: "24px", fontWeight: 400 }}>
                {`${country.name} +${country.value}`}
              </Typography>
            </Box>
          ))
        }
        </Box>
      </Box>
    </Box>)
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { name, email, country, id, created_at, designation, first_name, last_name, phone_number } = this.state.profileData.partner.data.attributes
    let update_name = name === null ? `${first_name} ${last_name}` : name
    return (
      //Required for all blocks
      <Box style={webStyles.container}>
        <Grid container>
          <Grid item xs={3} md={2} className="sidebar-wrapper">
            <SideNav {...this.props.navigation} />
          </Grid>
          <Grid item xs={9} md={10} className="main-content-wrapper">
            {
              this.state.isLoading ?
                // <Loader loading={this.state.isLoading} contentLoader={true} />
                <>
                  <TopNavSkeleton />
                  <ProfilePageSkeleton />
                </>
                :
                <Grid>
                  <TopNavBar currentPage={{ name: "Profile settings", path: 'UserProfileBasicBlockWeb' }} />
                  <Box style={webStyles.mainContainer}>
                    <Box className="profile-outer-block">
                      <Box className="profile-left-wrapper">
                        <Box className="profile-image-wrap">
                          <Avatar style={{ fontSize: '78px', border: '6.09px solid #FFFFFF' }} className="avtar-block purple-bg" src={`${this.state.imageUrl}`}>{getIntitals(getProfileName(this.state.profileData.partner.data.attributes))}</Avatar>
                          <Box className="upload-image-link" style={{ position: 'relative' }}>
                            <Typography onClick={() => this.openImageModal()} className="profile-photo-label">
                              {
                                this.state.profileData.partner.data.attributes.partner_image.url === null ?
                                  "Upload photo"
                                  :
                                  "Edit"
                              }
                            </Typography>
                            {
                              this.state.profileData.partner.data.attributes.partner_image.url !== null &&
                              <Typography onClick={() => this.removeImage()} data-test-id="removeBtn" className="profile-photo-label-remove">Remove</Typography>
                            }
                            <div style={{ display: this.state.isFirstTime ? 'block' : 'none' }}>
                              <div className="custom-popover bottom-side">
                                <div className="type-icon-wrap">
                                  <img src={user_active} alt="Bank details" />
                                  <img onClick={() => this.handleOnboardFlow()} className="close-popover" src={closeGrayIcon} alt="Close" />
                                </div>
                                <div className="type-title">Complete your profile</div>
                                <div className="type-desc">Get started by uploading a profile picture </div>
                              </div>
                            </div>
                          </Box>
                        </Box>
                      </Box>
                      <Box className="profile-right-wrapper">
                        <Box className="right-inner-wrap">
                          <Box>
                            <Grid className="profile-title-block">
                              <Box className="profile-left-block">
                                <Typography className="profile-name-label">
                                  {capitalizeFirstLetter(update_name)}
                                </Typography>
                                {
                                  getToken('partner_role') === 'admin' ?
                                    <Box style={{ display: 'flex' }}>
                                      <Typography className="member-badge-text" style={{ marginRight: '6px' }}>Admin</Typography>
                                      <Typography className="profile-since-label">
                                        since {getMonthDateFormat(created_at)}
                                      </Typography>
                                    </Box>
                                    :
                                    <Box className="badge-wrap">
                                      <Typography className="member-badge-text">Member</Typography>
                                    </Box>
                                }
                              </Box>
                              <Box className="profile-right-block">
                                <Typography onClick={() => this.openPersonalModal()} className="underline-link">
                                  Edit
                                </Typography>
                              </Box>
                            </Grid>
                          </Box>
                          <Box className="profile-email-container">
                            <Grid className="info-row">
                              <Grid className="info-title">
                                <Typography className="profile-email-label">Email</Typography>
                              </Grid>
                              <Grid className="info-content">
                                <Typography className="profile-email-value">{email}</Typography>
                              </Grid>
                            </Grid>
                            <Grid className="info-row">
                              <Grid className="info-title">
                                <Typography className="profile-email-label">Phone</Typography>
                              </Grid>
                              <Grid className="info-content">
                                <Typography className="profile-email-value">{getNullValues(phone_number)}</Typography>
                              </Grid>
                            </Grid>
                            {
                              (getToken('partner_role') === 'admin' && getToken('partner_id') === "null") &&
                              <>
                                <Grid className="info-row">
                                  <Grid className="info-title">
                                    <Typography className="profile-email-label">Designation</Typography>
                                  </Grid>
                                  <Grid className="info-content">
                                    <Typography className="profile-email-value">{getNullValues(designation)}</Typography>
                                  </Grid>
                                </Grid>
                                <Grid className="info-row">
                                  <Grid className="info-title">
                                    <Typography className="profile-email-label">Country</Typography>
                                  </Grid>
                                  <Grid className="info-content">
                                    <Typography className="profile-email-value">{getNullValues(country)}</Typography>
                                  </Grid>
                                </Grid>
                              </>
                            }
                          </Box>
                          <Box className="password-info-block">
                            <Box>
                              <Typography className="profile-password-label">Password</Typography>
                              <Typography className="profile-dot-label">•••••••••</Typography>
                            </Box>
                            <Box className="change-password-block">
                              <Typography onClick={() => this.openPasswordModal()} className="underline-link">Change password</Typography>
                            </Box>
                          </Box>
                          <Box className="logout-block">
                            <Button className="custom-btn red-btn btn-md" onClick={() => this.openLogoutlModal()}>Log out</Button>
                          </Box>
                          <Box className="get-in-touch-block">
                            <Typography className="profile-contact-label">
                              Have any queries regarding the partnership?  <label className="underline-link" onClick={this.handleDialogOpen}>Get in contact</label>
                            </Typography>
                            <HelpAndSupport openModal={this.state.openDialog} closeModal={this.handleDialogClose}/>
                            
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Modal
                    open={this.state.editPersonalModal}
                    onClose={() => {this.setState({countryDropDown:false,editPersonalModal:false})}}
                    data-test-id="personalModal"
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="custom-modal confirmation-modal modal-sm edit-profile-modal"
                    BackdropProps={{
                      className: "custom-modal-backdrop"
                    }}
                  >
                    <Box className="modal-body">
                      <Box>
                        <IconButton disableRipple className="close-btn" onClick={() =>{this.setState({editPersonalModal:false,countryDropDown:false})}}><img src={closeIcon} alt="close" /></IconButton>
                        <Typography variant="h4">
                          Edit personal details
                        </Typography>
                      </Box>
                      <Box>
                        {console.log('STATE: ', this.state.phoneNumberWithCode)}
                        <Formik
                          enableReinitialize={this.state.editPersonalModal}
                          initialValues={{
                            id: id,
                            name: update_name,
                            designation: designation,
                            country: { value: getSanitizedValues(country), label: getSanitizedValues(country) },
                            email: email,
                            phone_number: this.state.phoneNumberWithCode.number|| '',
                            countryCode: this.state.phoneNumberWithCode.countryCode || "+1",
                            countryFlag:this.state.phoneNumberWithCode.countryFlag || "us"
                          }}
                          validationSchema={getTrueValidataions((getToken('partner_role') === 'admin' && getToken('partner_id') === "null"), personalAdminEditSchema, personalMemberEditSchema)}
                          onSubmit={(values) => {
                            this.updatePartner(values)
                          }}
                        >
                          {(formik) => (
                            <form onSubmit={formik.handleSubmit}>
                              <Box >
                                <Box className="mt-1">
                                  <TextField
                                    name="name"
                                    size="small"
                                    variant="filled"
                                    type="text"
                                    label={"Your name"}
                                    fullWidth={true}
                                    onChange={(e) => {
                                      formik.handleChange(e)
                                    }}
                                    value={formik.values.name}
                                    error={this.getFormikError(formik.touched.name, formik.errors.name)}
                                    helperText={this.getFormikHelperText(formik.touched.name, formik.errors.name)}
                                  />
                                </Box>
                                <Box className="mt-1" style={{position:"relative"}}>
                                  <Box display={"flex"} alignItems={"center"} sx={{border:`solid 1px ${this.phoneBorderColor(formik)}`,borderRadius:"8px"}} className="phoneNumber">
                                    <Box
                                      display={"flex"}
                                      alignItems={"center"}
                                      data-test-id="dropDown"
                                      style={{ padding: "16px", borderRight: "solid 1px #C0C3CE", gap: "8px" }}
                                      onClick={()=>{this.setState({countryDropDown:!this.state.countryDropDown})}}
                                    >
                                      <span className={`fi fi-${formik.values.countryFlag}`}></span>
                                      <Typography style={{color: "#3C3E49"}}>{formik.values.countryCode}</Typography>
                                      <img src={dropDownIcon}/>
                                    </Box>
                                    <TextField
                                      name="phone_number"
                                      size="small"
                                      variant="filled"
                                      data-test-id="txtInputCurrentPassword"
                                      type={"text"}
                                      label={"Phone number"}
                                      // fullWidth={true}
                                      value={formik.values.phone_number}
                                      onChange={(e) => {
                                        formik.handleChange(e)
                                      }}
                                      className="phone-readonly-input"
                                      style={{flex:1}}
                                      />
                                  </Box>
                                  <p style={{color:"#D50000",fontSize:"14px",margin:"7px 0px"}}>
                                    {this.phoneHelperText(formik)}
                                    </p>
                                  {this.DropDown(formik)}
                                </Box>
                                {(getToken('partner_role') === 'admin' && getToken('partner_id') === "null") &&
                                  <>
                                    <Box className="mt-1">
                                      <TextField
                                        name="designation"
                                        size="small"
                                        variant="filled"
                                        type="text"
                                        label={"Your designation"}
                                        fullWidth={true}
                                        onChange={(e) => {
                                          formik.handleChange(e)
                                        }}
                                        value={formik.values.designation}
                                        error={this.getFormikError(formik.touched.designation, formik.errors.designation)}
                                        helperText={this.getFormikHelperText(formik.touched.designation, formik.errors.designation)}
                                      />
                                    </Box>
                                    <Box className="mt-1">
                                      <Box className={getWrapperClassName("custom-search-dropdown-wrapper", this.state.countryFocus ? "is-focused check-focus" : "is-focused", formik.values.country, this.state.countryFocus)}>
                                            <>
                                              {
                                                getSelectLabel(formik.values.country, 'select-label', 'Country')
                                              }
                                              <Select
                                                value={formik.values.country}
                                                options={this.state.countryData.map((country:any)=>({label:country.name,value:country.name}))}
                                                name="country"
                                                styles={selectStyle}
                                                components={{
                                                  IndicatorSeparator: () => null
                                                }}
                                                onChange={(option:any) =>
                                                  formik.setFieldValue('country', option)
                                                }
                                                placeholder="Country"
                                                className="custom-search-select small-listbox"
                                                onFocus={() => this.handleFocus('country')}
                                                onBlur={() => this.handleBlur('country')}
                                              />
                                            </>
                                        <ErrorMessage className="deal-error-text" name="country" component="div" />
                                      </Box>
                                    </Box>
                                  </>
                                }
                                <Box className="mt-1">
                                  <TextField
                                    name="email"
                                    size="small"
                                    variant="filled"
                                    data-test-id="txtInputCurrentPassword"
                                    type={"text"}
                                    label={"Email"}
                                    fullWidth={true}
                                    value={formik.values.email}
                                    disabled
                                    onChange={(e) => {
                                      formik.handleChange(e)
                                    }}
                                    className="readonly-input"
                                    error={this.getFormikError(formik.touched.email, formik.errors.email)}
                                    helperText={this.getFormikHelperText(formik.touched.email, formik.errors.email)}
                                    inputProps={
                                      {
                                        readOnly: true,
                                      }
                                    }
                                    InputProps={{
                                      endAdornment:
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="email"
                                            edge="end"
                                          >
                                            <img src={lockIcon} />
                                          </IconButton>
                                        </InputAdornment>
                                    }}
                                  />
                                </Box>
                                

                                <Box className="btn-wrap">
                                  <Button
                                    onClick={() => {
                                      formik.resetForm()
                                      this.setState({editPersonalModal:false})
                                    }}
                                    fullWidth
                                    size="small"
                                    variant="contained"
                                    type="button"
                                    className="custom-btn">Cancel</Button>
                                  <Button
                                    fullWidth
                                    size="small"
                                    variant="contained"
                                    type="submit"
                                    className="custom-btn green-btn">
                                    Save
                                  </Button>
                                </Box>
                              </Box>
                            </form>
                          )}
                        </Formik>
                      </Box>
                    </Box>
                  </Modal>
                  <Modal
                    open={this.state.changePasswordModal}
                    onClose={() =>{this.setState({changePasswordModal:false})}}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="custom-modal confirmation-modal modal-sm"
                    BackdropProps={{
                      className: "custom-modal-backdrop"
                    }}
                  >
                    <Box className="modal-body">
                      <Box>
                        <IconButton disableRipple className="close-btn" onClick={() =>{this.setState({changePasswordModal:false})}}><img src={closeIcon} alt="close" /></IconButton>
                        <Typography variant="h4">
                          Change password
                        </Typography>
                      </Box>
                      <Box>
                        <Formik
                          enableReinitialize
                          initialValues={{
                            currentPasswordText: '',
                            newPasswordText: '',
                            reTypePasswordText: ''
                          }}
                          validationSchema={changePasswordSchema}
                          onSubmit={(values) => {
                            this.checkCurrentPassword(values)
                          }}
                          innerRef={this.formikRef}
                        >
                          {(formik) => (
                            <form onSubmit={formik.handleSubmit}>
                              <Box >
                              <Box className="mt-1">
                                      <TextField
                                        name="currentPasswordText"
                                        size="small"
                                        variant="filled"
                                        data-test-id="txtInputCurrentPassword"
                                        type={this.getTextPassword(this.state.enablePasswordField, "password", "text")}
                                        label={"Enter current password"}
                                        fullWidth={true}
                                        value={formik.values.currentPasswordText}
                                        onChange={(e) => {
                                          this.setCurrentPassword(e.target.value)
                                          formik.handleChange(e)
                                        }}

                                        error={this.getFormikError(formik.touched.currentPasswordText, formik.errors.currentPasswordText)}
                                        helperText={this.getFormikHelperText(formik.touched.currentPasswordText, formik.errors.currentPasswordText)}
                                        InputProps={{
                                          endAdornment:
                                            <InputAdornment position="end">
                                              <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => this.handleShowCurrentPassword()}
                                                edge="end"
                                              >
                                                {getHideUnHidePassword(this.state.enablePasswordField, <img src={openEye} />, <img src={visiblityOff} />)}
                                              </IconButton>
                                            </InputAdornment>
                                        }}
                                      />
                                      <Typography className="deal-error-text" >{this.state.errorMessage}</Typography>
                                    </Box>
                                      <Box className="mt-1">
                                        <TextField
                                          name="newPasswordText"
                                          size="small"
                                          variant="filled"
                                          data-test-id="txtInputNewPassword"
                                          type={this.getTextPassword(this.state.enableNewPasswordField, "password", "text")}
                                          label={"Enter new password"}
                                          fullWidth={true}
                                          value={formik.values.newPasswordText}
                                          onChange={(e) => {
                                            this.setNewPassword(e.target.value)
                                            formik.handleChange(e)
                                          }}

                                          error={this.getFormikError(formik.touched.newPasswordText, formik.errors.newPasswordText)}
                                          helperText={this.getFormikHelperText(formik.touched.newPasswordText, formik.errors.newPasswordText)}
                                          InputProps={{
                                            endAdornment:
                                              <InputAdornment position="end">
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={() => this.handleShowNewPassword()}
                                                  edge="end"
                                                >
                                                  {getHideUnHidePassword(this.state.enableNewPasswordField, <img src={openEye}></img>, <img src={visiblityOff} />)}
                                                </IconButton>
                                              </InputAdornment>
                                          }}
                                        />
                                      </Box>
                                      <Box className="mt-1">
                                        <TextField
                                          name="reTypePasswordText"
                                          size="small"
                                          variant="filled"
                                          data-test-id="txtInputReTypePassword"
                                          type={this.getTextPassword(this.state.enableReTypePasswordField, "password", "text")}
                                          label={"Enter confirm password"}
                                          fullWidth={true}
                                          value={formik.values.reTypePasswordText}
                                          onChange={(e) => {
                                            this.setReTypePassword(e.target.value)
                                            formik.handleChange(e)
                                          }}

                                          error={this.getFormikError(formik.touched.reTypePasswordText, formik.errors.reTypePasswordText)}
                                          helperText={this.getFormikHelperText(formik.touched.reTypePasswordText, formik.errors.reTypePasswordText)}
                                          InputProps={{
                                            endAdornment:
                                              <InputAdornment position="end">
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={() => this.handleShowReTypePassword()}
                                                  edge="end"
                                                >
                                                  {getHideUnHidePassword(this.state.enableReTypePasswordField, <img src={openEye} />, <img src={visiblityOff} />)}
                                                </IconButton>
                                              </InputAdornment>
                                          }}
                                        />
                                      </Box>
                                    
                                <Box className="btn-wrap mt-40">

                                  <Button
                                    onClick={() => {
                                      formik.resetForm()
                                      this.closeModal()
                                    }}
                                    fullWidth
                                    size="small"
                                    variant="contained"
                                    type="button"
                                    className="custom-btn">Cancel</Button>

                                  <Button
                                    fullWidth
                                    size="small"
                                    variant="contained"
                                    type="submit"
                                    className="custom-btn green-btn">
                                    Save changes
                                  </Button>
                                </Box>
                              </Box>
                            </form>
                          )}
                        </Formik>
                      </Box>
                    </Box>
                  </Modal>
                  <Modal
                    open={this.state.uploadImageModal}
                    onClose={() => this.closeModal()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="custom-modal confirmation-modal modal-sm"
                    BackdropProps={{
                      className: "custom-modal-backdrop"
                    }}
                  >
                    <Box className="modal-body">
                      <Box>
                        <IconButton disableRipple className="close-btn" onClick={() => this.closeModal()}><img src={closeIcon} alt="close" /></IconButton>
                        <Typography variant="h4">
                          Upload your profile picture
                        </Typography>
                      </Box>
                      <ImageCropper errorMessage={this.state.fileError} imageLink={this.state.imageUrl} getFile={(data: any) => {
                        this.setState({ selectedFile: data })
                      }} />
                      <Box style={{ textAlign: 'center' }}>
                        <input
                          type="file"
                          style={{ display: 'none' }}
                          ref={this.fileInputRef}
                          onChange={this.handleFileInputChange}
                        />
                        <Button onClick={this.openFileInput} className="upload-picture-button">
                          <img src={uploadIcon} className="mr-8" />
                          <Typography className="upload-picture-label">Upload new picture</Typography>
                        </Button>
                      </Box>
                      <Box className=" btn-wrap mt-40">
                        <Button
                          onClick={() => this.closeModal()}
                          fullWidth
                          size="small"
                          variant="contained"
                          type="button"
                          className="custom-btn">Cancel</Button>
                        <Button
                          onClick={() =>
                            this.fileUpload()
                          }
                          fullWidth
                          size="small"
                          variant="contained"
                          type="button"
                          className="custom-btn green-btn">
                          Save picture
                        </Button>
                      </Box>
                    </Box>
                  </Modal>
                  <Modal
                    open={this.state.logoutModal}
                    onClose={this.closeModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="custom-modal confirmation-modal modal-xsm"
                    BackdropProps={{
                      className: "custom-modal-backdrop"
                    }}
                  >
                    <Box className="modal-body">
                      <Box>
                        <IconButton data-test-id="btn-close-invite-modal3" disableRipple className="close-btn" onClick={() => this.closeModal()}><img src={closeIcon} alt="close" /></IconButton>
                        <Typography variant="h4">
                          Are your sure you want to Log out?
                        </Typography>
                      </Box>
                      <Box className="mt-1 btn-wrap">

                        <Button
                          data-test-id="btn-close-invite-modal4"
                          onClick={() => {
                            this.closeModal()
                          }}
                          fullWidth
                          size="small"
                          variant="contained"
                          type="button"
                          className="custom-btn btn-md">
                          Cancel
                        </Button>
                        <Button
                          fullWidth
                          size="small"
                          variant="contained"
                          type="button"
                          data-test-id="logoutbtn"
                          className="custom-btn red-btn btn-md"
                          onClick={() => this.handleLogout()}
                        >
                          Log out
                        </Button>
                        <iframe
                          src={this.state.buUrl}
                          width={'100%'}
                          height={'100%'}
                          frameBorder="0"
                          style={{ display: "none" }}
                          id="builderUniversity"
                          onLoad={async()=>{
                            await setStorageData("sessionExist","true")
                          }}
                        />
                      </Box>
                    </Box >
                  </Modal >
                </Grid>
            }
          </Grid>
        </Grid>
      </Box >
    );
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getValidations();
    this.requestSessionData();
    await this.getUserProfileInfo()
    // await this.getSelectCompanies()
    await this.getCountries()
    if(await getStorageData("leavedPage") && isApplicationNotReloaded()){
      trackEvent("prm_home_page_visited","user lands on the the PRM portal",{dewey_code:"B3.1",flow:1})
      await removeStorageData("leavedPage")
    }
    window.addEventListener('unload', async()=>{
      await setStorageData("leavedPage","true")
    });
    const pathname=window.location.pathname
        if(pathname.includes('training')){
            this.setState({createIframe:false})
        }
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

}
// Customizable Area Start
const webStyles = {
  container: {
    background: '#F8F9FA',
    height: '100vh',
    overflow: 'auto',
  },
  mainContainer: {
    padding: '22px 0'
  },
};
const PasswordModalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 3,
  p: 4,
};
const UploadModalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 3,
  p: 4,
};
const selectStyle = {
  control: (styles: any) => ({
    ...styles,
    height: 56,
    border: '1px solid #C0C3CE',
    boxShadow: 'none',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #C0C3CE',
      boxShadow: 'none'
    },
    '&::placeholder': {
      marginTop: '10px',
      color: '#83889E',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '24px',
      fontFamily: "'Rubik', sans-serif",
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 9999,
    borderRadius: '8px',
    border: '1px solid var(--true - grey - 3, #F5F5F5)',
    background: 'var(--0, #FFF)',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
    paddingBottom: 0,
    paddingTop: 0
  }),
  menuList: (provided: any) => ({
    ...provided,
    paddingBottom: 0,
    paddingTop: 0,
    borderRadius: 8,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    background: state.isSelected ? '#F3EBFF' : 'white',
    color: state.isSelected ? '#3C3E49' : '#3C3E50',
    '&:hover': {
      background: '#F3EBFF',
    },
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    padding: '12px 16px',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch'
  }),
  noOptionsMessage: (provided: any) => ({
    ...provided,
    background: '#F3EBFF',
    display: 'flex',
    alignItems: 'center',

  }),
  singleValue: (provided: any) => ({
    ...provided,
    marginRight: '0px',
    marginTop: '12px', // Adjust margin as needed q
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: "#3C3E49",
    padding: '16px'
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '2px 14px',
  }),
  input: (provided: any) => ({
    ...provided,
    marginTop: '15px',
    paddingTop: '0px',
    caretColor: 'transparent'
  })
};
// Customizable Area End
